import React, {useState, useEffect} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import toast from 'react-hot-toast'
import {fetchQuestApi, fetchGuildApi} from 'services/wallet'
import {ethers} from 'ethers'
import ConnectWallet from './ConnectWallet'
import {logEvent} from 'utilis/logEvents'


const Wallet = () => {

    const location = useLocation();
    const history = useHistory()
    const [discordHash, setDiscordHash] = useState(null);
    const [questId, setQuestId] = useState()
    const [showConnect, setShowConnect] = useState(false);
    const [guild, setGuild] = useState()
    const [quest, setQuest] = useState()
    const [guildId, setGuildId] = useState()

    useEffect(() => {
        getQueryParams();
    }, [])
    

    const getQueryParams = async () => {
        const data = {};
        const queryParams = new URLSearchParams(location.search)
        queryParams.forEach((value, key) => {
            data[key] = value;
        });
    
        if(data.guildId){
            fetchGuild(data.guildId)
            setGuildId(data.guildId)
        }
       
        if(data.token){
            setDiscordHash(data.token)
        }
        if(data.questId){
            setQuestId(data.questId)
            fetchQuest(data.questId, data.guildId)
        }
    }



    const fetchGuild = async (id) => {
        try{

            const res = await fetchGuildApi(id)
            console.log(res)
            setGuild({...res, id})

        } catch(err){
            console.log(err)
        }
    }



    const fetchQuest = async (id, guildId) => {
        try{

            const res = await fetchQuestApi(id)
            logEvent('QUEST::WEBSITE::START', {
                questId: id,
                guildId: guildId,
            })
            setQuest(res)

        } catch(err){
            console.log(err)
        }
    }


    if(!quest && !guild){
        return (
            <div class='d-flex align-items-center min-h-100 container-fluid' style = {{height: '100vh'}}>
                <Background />
                <div class="container py-5 py-sm-7">
                    <a class="d-flex justify-content-center mb-5" href="https://intract.buzz" target="_blank">
                        <img class="z-index-2" src="/assets/logos/logo.svg" alt="Image Description" style={{width: "8rem"}} />
                    </a>
                    <div class="row justify-content-center">
                    <div class='w-100 text-center'>
                        <span class='spinner-border spinner-border-lg'></span>
                    </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div class='d-flex align-items-center min-h-100 container-fluid ' style = {{height: '100vh'}}>
            <Background />
            <div class="container py-5 py-sm-7">
                <a class="d-flex justify-content-center mb-5" href="https://intract.buzz" target="_blank">
                    <img class="z-index-2" src="/assets/logos/logo.svg" alt="Logo" style={{width: "8rem"}} />
                </a>
                <div class="row justify-content-center">
                    <div class="col-md-7 col-lg-5">
                        <div class="card card-lg mb-5">
                            <div class="card-body text-center py-6 my-4">
                                <div class="mb-2">
                                    <img class="avatar avatar-xxl " src="./assets/a.png" alt="Icon" style = {{width: '10.875rem'}}/>
                                    <h1 class="display-5 mt-2">{guild.name}</h1>
                                </div>
                                <div class="mb-5">
                                    <h1 class="display-4">Connect your wallet</h1>
                                    <p class="mb-0">Click on “Connect my account” to securely connect your wallet.</p>
                                </div>
                                <span 
                                    type="submit" 
                                    class="btn btn-lg btn-block btn-primary"
                                    onClick={() => setShowConnect(true)}
                                >
                                    Connect my account
                                </span>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            {quest && <ConnectWallet 
                show = {showConnect}
                setShow = {setShowConnect}
                discordHash = {discordHash}
                questId = {questId}
                quest = {quest}
                guildId = {guildId}
            />}
        </div>
    )
}

export default Wallet



const Background = () => {
    return (
        <div class="position-fixed top-0 right-0 left-0 bg-img-hero" style={{height: '32rem', backgroundImage: "url(/assets/abstract.svg)"}}>
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 "></polygon>
          </svg>
        </figure>
      </div>
    )
}
