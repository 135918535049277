import React, { useState } from 'react'

const Tags = ({tags, isClearOption, clearTag}) => {
    return (
        <div class>
            {tags.map((item, index) => <span class={`badge mr-2 badge-soft-${item.color ? item.color : 'primary'}`} key = {index}>
                {item.icon && <i class={'mr-1 tio-' + item.icon}></i>}
                {item.label}
                {isClearOption && <i class='tio-clear ml-2 pointer' onClick={() => clearTag(item.value)}></i>}
            </span>)}
        </div>
    )
}



export default Tags