import ReactGA from 'react-ga';
import Posthog from 'posthog-js'
import mixpanel from 'mixpanel-browser';


let isInitialized = false;

export const initAnalysis = () => {
    try{

        ReactGA.initialize('UA-81683787-3');
        Posthog.init('phc_P2RUQfi6du8BgQtw0nI65umKgjGZEbvcO9HvxBMNPWv', { api_host: 'https://app.posthog.com' })
        mixpanel.init('17d3ab9de47c2ed43a8b857420cd67b6', {api_host: "https://api.mixpanel.com"}); 
        isInitialized = true;

    } catch(err){
        console.log(err)
    }
};

export const logPageView = () => {
    try{
        if(mixpanel.__loaded){
            ReactGA.set({ page: window.location.pathname });
            ReactGA.pageview(window.location.pathname);
            mixpanel.track('PAGE_VIEW::MMEMBERS', {
                'page': window.location.pathname
            }); 
        }
    } catch(err){
        console.log(err)
    }
};

export const logEvent = (eventName, attributes) => {
    try{
        if(mixpanel.__loaded){
            mixpanel.track(eventName, attributes);
        }
    } catch(err){
        console.log(err)
    }
};

export const logUser = (email) => {
    try{
        if(email && mixpanel.__loaded){
            Posthog.identify(email);
            ReactGA.event({ category: 'User', action: 'Signed up', value: email });
            window.twq('track','sign_up');
            mixpanel.identify(email);
        }
    } catch(err){
        console.log(err)
    }
}

export const updateUserProfile = (profile) => {
    if(email){
        // mixpanel.people.set({
        //     ...profile
        // });
    }
}



export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
