import React, {useState, useEffect, useRef} from 'react'
import {useMoralis, ValidationError} from 'react-moralis'
import { Button, ButtonGroup } from 'component/Forms/Buttons'
import { InputField } from 'component/Forms/TextInput'
import { submitTaskApi } from 'services/quests'
import toast from 'react-hot-toast'
import MyDropzone from 'component/Forms/Dropzone'
import validations from 'utilis/validation'
import openSocket from 'socket.io-client'
import {v4} from 'uuid'
const API_URL = 'http://localhost:8000'
// const socket = openSocket(API_URL, {reconnect: true})


const ClaimReward = ({quest, completed, userId, submissions, setSubmissions, setShow}) => {
    
    const [loading, setLoading] = useState(false)
    const { isAuthenticated, user } = useMoralis();
    const [submission, setSubmission] = useState('');
    const [error, setError] = useState(false);
    const [file, setFile] = useState([]);
    const [socketId, setSocketId] = useState('abhishek');
    console.log(socketId)

    useEffect(() => {
        setError(false)
    }, [file, submission])

    // useEffect(() => {
    //     console.log("Subscribing to auth")
    //     subscribeToAuth()
    // }, [])
    

    const submitTask = async () => {
        try{
            setLoading(true)
            if(quest.type.includes('CUSTOM')) {
                await submitCustom()
            }
            if(quest.type.includes('TWITTER')) {
                await startTwitterAuth()
            }
            if(quest.type.includes('DISCORD')) {
                await startDiscordAuth()
            }
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    const submitCustom = async () => {
        try{
            if(!validate()) {
                setLoading(false)
                return;
            }
            const formData = new FormData()
            formData.append('address', user.get('ethAddress'))
            formData.append('userId', userId)
            formData.append('type', quest.evaluation.type)
            if(quest.evaluation.type === 'IMAGE'){
                formData.append('submittedFile', file[0])
            } else{
                formData.append('submission', submission)
            }
            let guildName = quest.guildSlug;
            const submission_ = await submitTaskApi(guildName, quest._id, formData)
            toast.success("Quest successfully claimed")
            setSubmissions([...submissions, submission_])
            setLoading(false)
            setShow(false)
        } catch(err){
            setLoading(false)
            throw err;
        }
    }
 
   
    const validate = () => {
        if(quest.evaluation.type === 'IMAGE'){
            if(file.length === 0){
                setError(true)
                return false;
            }
        }
        if(quest.evaluation.type === 'TEXT'){
            if(submission === ''){
                setError(true)
                return false;
            }
        }
        if(quest.evaluation.type === 'LINK'){
            if(validations.isUrl(submission) === false){
                setError(true)
                return false;
            }
        }
        return true;
    }
 
   
    const startDiscordAuth = async () => {
        try{
            const link = "http://localhost:8000/api/v1/quests/public/auth/discord";
            const REDIRECT = 'http://localhost:3001/auth/redirect';
            const win_ =  openAuthWindow(link)

            var pollTimer = window.setInterval(async function() { 
                try {
                    if (win_.document.URL.indexOf(REDIRECT) != -1) {
                        window.clearInterval(pollTimer);
                        var url =   win_.document.location.search;
                        const data = {};
                        const queryParams = new URLSearchParams(url)
                        queryParams.forEach((value, key) => {
                            data[key] = value;
                        });
                        win_.close();
                        await completeDiscordAuth(data)
                    }
                } catch(e) {
                    console.log(e)
                }
            }, 500);
        } catch(err){
            throw err;
        }
    }

  

    const startTwitterAuth = async () => {
        try{
            
            const link = `http://localhost:8000/api/v1/quests/public/auth/twitter?userId=${userId}&questId=${quest._id}&socket=${socketId}`;
            openAuthWindow(link)
            
        } catch(err){
            throw err;
        }
    }

  

    const openAuthWindow = (link) => {
        const left = (window.innerWidth / 2) - (400 / 2)
        const top = (window.innerHeight / 2) - (600 / 2)
        let win_ = window.open(link, 'twitter', `toolbar=no, location=no, directories=no, status=no, menubar=no,
        scrollbars=no, resizable=no, copyhistory=no, width=400, 
        height=600, top=${top}, left=${left}`)
        return win_        
    }


    const completeDiscordAuth = async (data) => {
        try{

            const formData = new FormData()
            formData.append('address', user.get('ethAddress'))
            formData.append('userId', userId)
            formData.append('type', quest.evaluation.type)
            formData.append('submission', data.code)
            let guildName = quest.guildSlug;
            const submission_ = await submitTaskApi(guildName, quest._id, formData)
            toast.success("Quest successfully claimed")
            setSubmissions([...submissions, submission_])
            setLoading(false)
            setShow(false)

        } catch(err){
            setLoading(false)
            if(err && err.response && err.response.data && err.response.data.error) toast.error(err.response.data.error)
            else toast.error("Something went wrong")
        }
    }


    return (
        <div class='card bg-soft-light card-hover-shadow mt-4'>
            <div class='card-body '>
                {quest.evaluation.type === 'LINK' && <InputField 
                    label = 'Your submission'
                    labelClass="bold font-size-lg"
                    description="Submit your work respecting the rules. We will verify, and you will receive your rewards"
                    value = {submission}
                    setValue = {e => setSubmission(e)}
                    error = {error}
                    errorText = "Please enter a valid link"
                    setError={e => setError(e)}
                    placeholder = "https://www.google.com"
                />}
                {quest.evaluation.type === 'TEXT' && <InputField 
                    label = 'Your submission'
                    labelClass="bold font-size-lg"
                    description="Submit your work respecting the rules. We will verify, and you will receive your rewards"
                    value = {submission}
                    setValue = {e => setSubmission(e)}
                    error = {error}
                    errorText = "Please enter your submission"
                    setError={e => setError(e)}
                    placeholder = "https://www.google.com"
                    type = "textarea"
                />}
                {quest.evaluation.type === 'IMAGE' &&  (
                    <>
                    <MyDropzone 
                        onFileChange = {(file) => setFile(file)}
                        files = {file} 
                        height = "100px" 
                        isMulti = {false} 
                        type = 'pdf'
                    />
                    {error && <p class='text-danger'>Please upload a valid file</p>}
                    </>
                )}
                {quest.evaluation.type === 'TWITTER_AUTH' && <div>
                    <label class='input-label bold'>How to claim</label>
                    <p>After you complete the task, verify using your twitter account to claum the reward!</p>
                </div>}
                {quest.evaluation.type === 'DISCORD_AUTH' && <div>
                    <label class='input-label bold'>How to claim</label>
                    <p>After you complete the task, verify using your discord account to claum the reward!</p>
                </div>}
                {quest.evaluation.type === 'VISIT_LINK' && <div>
                    <label class='input-label bold'>How to claim</label>
                    <p>Please visit the link through "Visit Link" button and then you can claim the task</p>
                </div>}
                <Button 
                    text = {(isAuthenticated) ? "Claim Reward" : <><i class='tio-lock mr-2'></i>Claim Reward</>}
                    loading = {loading}
                    block
                    color = {completed ? 'primary' : 'secondary'}
                    disabled={!completed}
                    size="lg"
                    className='mt-3'
                    submitResponse = {submitTask}
                />
            </div>
        </div>
    )
}

export default ClaimReward