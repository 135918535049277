import React, {useState, useEffect} from 'react'
import toast from 'react-hot-toast'
import {submitDataApi, fetchQuestApi} from 'services/wallet'
import {logEvent} from 'utilis/logEvents'
import ConnectWallet from './ConnectWallet'
import validations from 'utilis/validation'

const Notification = (props) => {

    const [loading, setLoading] = useState(false);
    const [questId, setQuestId] = useState('');
    const [guildId, setGuildId] = useState('');
    const [quest, setQuest] = useState({})
    const [discordHash, setDiscordHash] = useState('')
    const [chain, setChain] = useState('solana')
    const [values, setValues] = useState({email: '', telegram: ''})
    const [alerts, setAlerts] = useState([]);
    const [account, setAccount] = useState()
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({email: false, telegram: false})


    useEffect(() => {
        getQueryParams();
        
    }, [])

    useEffect(() => {
        setErrors({email: false, telegram: false})
    }, [values])


    const getQueryParams = async () => {
        const data = {};
        const queryParams = new URLSearchParams(location.search)
        queryParams.forEach((value, key) => {
            data[key] = value;
        });
        if(data.token){
            setDiscordHash(data.token)
        }
        if(data.questId){
            setQuestId(data.questId)
            fetchQuest(data.questId, data.guildId)
        }
        if(data.guildId){
            setGuildId(data.guildId)
        }
    }

    const fetchQuest = async (id) => {
        try{

            const quest_ = await fetchQuestApi(id)
            setQuest(quest_)
            setChain(quest_?.evaluation?.metadata?.walletChain)
            getConnectedAccounts(quest_?.evaluation?.metadata?.walletChain);
            setAlerts(quest_?.evaluation?.metadata?.alerts ? quest_?.evaluation?.metadata?.alerts.map(item => ({key: item, label: item, response: true})) : [])

        } catch(err){
            console.log(err)
        }
    }

    
    const getConnectedAccounts = async (chain_) => {
        try{
            
            if(chain_ === 'solana') {
                if(!isPhantomInstalled()){
                    toast.error('Please install Phantom wallet!')
                    return;
                }
                const res = await window.phantom.solana.connect()
                const account_ = res.publicKey.toString()
                if(account_){
                    setAccount(account_)
                }

            } else {
                if(!isMetaMaskInstalled()){
                    toast.error('Please install MetaMask wallet!')
                    return;
                }
                const accounts_ = await window.ethereum.request({ method: 'eth_accounts' })
                if(accounts_ && accounts_.length > 0){
                    setAccount(accounts_[0])
                }
            }

        } catch(err){
            console.log(err)
        }
    }
    
    const toggleAlerts = (key) => {
        setAlerts(alerts.map(alert => {
            if(alert.key === key){
                alert.response = !alert.response
            }
            return alert
        }))
    }

    const validate = () => {
        let new_errors = {email: false, telegram: false}
        if(!validations.isEmail(values.email)) new_errors.email = true
        if(validations.isEmpty(values.telegram)) new_errors.telegram = true
        setErrors(new_errors)
        if(new_errors.email || new_errors.telegram) return false
        else return true;
    }

    const updateData = async (e) => {
        e.preventDefault();
        if(!validate()) return;
        if(account){
            await submitData(account)
        } else {
            if(chain === 'solana'){
                await connectSolanaWallet()
            } else {
                await connectMetamaskWallet()
            }
        }
    }


    const submitData = async (account) => {
        try{
            setLoading(true)
            await submitDataApi(questId, {
                wallet: account,
                email: values.email,
                telegram: values.telegram,
                alerts,
                discordHash,
            })
            toast.success("Submission successful!")
            setTimeout(() => {
                window.location.href = (`https://discord.com/channels/${guildId}`);
            }, 1000)
            setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    const isMetaMaskInstalled = () => {
        const { ethereum } = window;
        return Boolean(ethereum && ethereum.isMetaMask);
    };
  
    const isPhantomInstalled = () => {
        const { phantom } = window;
        return Boolean(phantom);
    };


    const connectSolanaWallet = async () => {
        if(!isPhantomInstalled()){
            toast.error('Please install Phantom wallet!')
            return;
        }
        setShow(true)
        try{
            const res = await window.phantom.solana.connect()
            const account_ = res.publicKey.toString()
            if(account_) {
                setAccount(account_)
                await submitData(account_)
                setShow(false)
            }
        } catch(err){
            console.log(err)
            toast.error("Something went wrong, please try again")
        }
    }
    

    const connectMetamaskWallet = async () => {
        if(!isMetaMaskInstalled()){
            toast.error('Please install MetaMask wallet!')
            return;
        }
        setShow(true)
        try{
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if(accounts && accounts.length > 0) {
                setAccount(accounts[0])
                await submitData(accounts[0])
                setShow(false)
            }

        } catch(err){
            console.log('err')
            toast.error("Something went wrong, please try again")
        }
    };

    return (
        <div class='d-flex align-items-center  container-fluid ' style = {{minHeight: '100vh'}}>
            <Background />
            <div class="container ">
                <a class="d-flex justify-content-center mb-3" href="https://intract.buzz" target="_blank">
                    <img class="z-index-2" src="/assets/logos/logo.svg" alt="Logo" style={{width: "8rem"}} />
                </a>
                <div class="row justify-content-center">
                    <div class="col-md-7 col-lg-5">
                        <div class="card card-lg mb-5">
                            <div class='card-header justify-content-center'>
                                <h1 class="display-5 mb-0 ">Set up notifications</h1>
                            </div>
                            <div class="card-body ">
                                <div class="mb-4">
                                    <label for="inputGroupMergeEmail" class="form-label">Email</label>
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend input-group-text" id="inputGroupMergeEmailAddOn">
                                            <i class="tio-email"></i>
                                        </div>
                                        <input 
                                            type="text" 
                                            class={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="mark@example.com" 
                                            value = {values.email}
                                            onChange = {e => setValues(prev => ({...prev, email: e.target.value}))}
                                        />
                                    </div>
                                    {errors.email && <div class="invalid-feedback d-block">Please enter a valid email</div>}
                                </div>
                                <div class="mb-5">
                                    <label for="inputGroupMergeEmail" class="form-label">Telegram</label>
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend input-group-text" id="inputGroupMergeEmailAddOn">
                                            <i class="tio-telegram"></i>
                                        </div>
                                        <input 
                                            type="text" 
                                            class={`form-control ${errors.telegram ? 'is-invalid' : ''}`}
                                            placeholder="abhishekanita" 
                                            value = {values.telegram}
                                            onChange = {e => setValues(prev => ({...prev, telegram: e.target.value}))}
                                        />
                                    </div>
                                    {errors.telegram && <div class="invalid-feedback d-block">Please enter a valid telegram id</div>}
                                </div>
                                {alerts.length === 0 ? <>
                                    <div class='text-center'>
                                        <span class='spinner-border spinner-border-lg'></span>
                                    </div>
                                </> : alerts.map((item, index) => <div class={`row align-items-center mb-2 ${index === 0 ? 'border-top' : ''} pt-3 border-bottom pb-3`}>
                                    <div class='col text-dark'>
                                        {item.label}
                                    </div>
                                    <div class='col-auto'>
                                        <label class="switch-2">
                                            <input type="checkbox" onChange = {e => toggleAlerts(item.key)} checked = {item.response}/>
                                            <span class="slider-2 round-2"></span>
                                        </label>
                                    </div>
                                </div>)}

                                <button 
                                    type="submit" 
                                    class="btn btn-lg btn-block btn-primary mt-6"
                                    onClick={(e) => updateData(e)}
                                    disabled = {!quest || alerts.length === 0 || loading}
                                >
                                    {loading && <span class='spinner-border spinner-border-sm mr-2'></span> }
                                    Authorize your submission
                                </button>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            <ConnectWallet 
                show = {show}
                setShow = {setShow}
                chain = {chain}
            />
        </div>
    )
}

export default Notification



const Background = () => {
    return (
        <div class="position-fixed top-0 right-0 left-0 bg-img-hero" style={{height: '32rem', backgroundImage: "url(/assets/abstract.svg)"}}>
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 "></polygon>
          </svg>
        </figure>
      </div>
    )
}


export const SwitchRow = ({title, svg, value, onChange, description}) => {
    return(
        <>
        <div class="d-flex flex-stack">
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <span class="fs-5 text-dark text-hover-primary fw-boldest">{title}</span>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <div class="form-check form-check-solid form-switch">
                    <input 
                        class="form-check-input w-45px h-30px" 
                        type="checkbox" 
                        checked = {value}
                        onChange = {() => onChange(!value)}
                    />
                    <label class="form-check-label" ></label>
                </div>
            </div>
        </div>
        </>
    )
}


const sampleAlerts = [{
    label: 'Get deposit alerts',
    key: 'getDeposite',
    response: true
}, {
    label: 'Port Finance Broadcast Messages',
    key: 'postFinance',
    response: true
}, {
    label: 'Get liquidation alerts',
    key: 'liquidation',
    response: true
}]