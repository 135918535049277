import { SimpleModal } from 'component/Models/Modal'
import React, {useState, useEffect, useContext} from 'react'
import toast from 'react-hot-toast'
import { useMoralis, useNativeBalance } from 'react-moralis'
import { submitQuestApi, } from 'services/wallet'
import {logEvent} from 'utilis/logEvents'


const ConnectWallet = ({show, setShow}) => {
    
    


    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
        >
            <div class='py-6'>
                <div class='px-6 text-center mb-6 mt-5'>
                    <img src = "/assets/metamask.png" class='img-fluid' style = {{height: '150px'}}/>
                    <div class='h2 mb-1'>Waiting for confirmation from Metamask</div>
                    <span class='spinner-border spinner-border-lg mt-5'></span>
                </div>
            </div>
            
        </SimpleModal>
    )
}

export default ConnectWallet