import axiosInstance from './'

export const submitQuestApi = async (questId, body) => {
    try{
        const res = await axiosInstance.post(`/quests/public/quest/${questId}/submission/discord`, body)
        return res.data

    } catch(err){
        console.log(err)
        throw err;
    }
}


export const submitDataApi = async (questId, body) => {
    try{
        const res = await axiosInstance.post(`/quests/public/quest/${questId}/submission/email`, body)
        return res.data

    } catch(err){
        console.log(err)
        throw err;
    }
}


export const fetchQuestApi = async (questId) => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/quests/public/quest/${questId}/`)
        return data

    } catch(err){
        console.log(err)
        throw err;
    }
}


export const fetchGuildApi = async (id) => {
    try{
        const res = await axiosInstance.get(`/quests/public/${id}/guild`)
        return res.data.data

    } catch(err){
        console.log(err)
        throw err;
    }
}


