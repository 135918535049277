import React, { useEffect } from 'react';
import AppRouter from "./routes";
import {initAnalysis} from 'utilis/logEvents'
import { BrowserRouter as Router } from 'react-router-dom';
import 'assets/css/index.css'
import {Toaster} from 'react-hot-toast'
import { MoralisProvider } from "react-moralis";

const App = (props) => {
    
    useEffect(() => {
        // if(!window.location.host.includes('localhost')){
            initAnalysis()
        // }   
    }, [])

    return (
        <>
            <MoralisProvider
                serverUrl="https://udzkijhvkfq1.usemoralis.com:2053/server" 
                appId="yFK6WBEvTV25MGy6GRlD8qpbOfoJ61WAvKK5iu68"
            >
                <Toaster />
                <Router>
                    <AppRouter />
                </Router>
            </MoralisProvider>
        </>
    );
}

export default App;

