import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Tags from 'component/Others/Tags'





const Avatar = ({name, email, description, tags, icon, time, avatar, link, size}) => {

    const history = useHistory()

    return (
        <a class={`media align-items-center ${link ? 'pointer' : ''}`} onClick={() => {
            if (link) history.push(link)
        }}>
            <div class={`avatar avatar-${size ? size : 'md'} avatar-soft-secondary avatar-circle mr-2`}>
                {avatar ? 
                    <img class="avatar-img" src={avatar} alt="Image Description" /> :
                    <span class="avatar-initials">{name && name[0].toUpperCase()}</span>
                }
            </div>
            <div class="media-body">
                <span class={`d-block h5 ${link ? 'text-hover-primary' : ''} mb-0  `}>{name} {icon && icon}</span>
                {tags && <Tags tags = {tags}/>}
                {email && <span class="d-block font-size-sm text-body">{email}</span>}
                {description && <span class="d-block font-size-sm text-body">{description}</span>}
                {time && <p class="font-size-sm mt-2"><i class='tio-time mr-1'></i>{time}</p>}
            </div>
        </a>
    )
}

export default Avatar


export const AvatarGroup = ({users, maxLength, size}) => {

    const history = useHistory()


    return (
        <div class="d-flex">
            <div class={`avatar-group ${size && `avatar-group-${size}`} avatar-circle mr-3`}>
                {users.slice(0, maxLength).map(item => <OverlayTrigger overlay = {<Tooltip>{item.name}</Tooltip>}>
                    <span class={`avatar  ${item.link ? 'pointer' : ''}`} onClick={() => {
                        if (item.link) history.push(item.link)
                    }}>
                        {item.avatar ? <img class="avatar-img" src={item.avatar} alt={item.name} />:
                        <span class="avatar-initials">{item.name[0].toUpperCase()}</span>}
                    </span>
                </OverlayTrigger>)}
                {users.length > maxLength && <span class="avatar avatar-light avatar-circle" >
                    <span class="avatar-initials">+{users.length - maxLength}</span>
                </span>}
            </div>
        </div>
    )
}


