import Avatar from 'component/Avatar'
import TableCard from 'component/Table/TableCard'
import React from 'react'

const Leaderboard = () => {
    
  return (
    <div class='mt-4'>
        <TableCard 
            headers = {headers}
            data = {(sampleData && sampleData.length > 0) ? sampleData : []}
            title = "Leaderboard"
        />
    </div>
  )
}

export default Leaderboard


const headers = [
    {value: 'name', label: 'Name'},
    {value: 'level', label: 'Level'},
    {value: 'numQuests', label: 'Number of Quests'},
    {value: 'totalXps', label: 'Total XPs'},
]


const sampleData = Array(5).fill(null).map(item => ({
    name: {
        value: 'John Doe',
        label: <Avatar 
            name = "Abhishek"
            avatar = "/assets/img/bee.png"
        />,
    },
    level: 'Level 1',
    numQuests: '12 quests',
    totalXps: '1200 xps',
}))