import axios from 'axios'
import toast from 'react-hot-toast'
import config from 'utilis/config'

const API_URL = config.serverLink + "/api/v1";

const axiosInstance =  axios.create({
    baseURL: API_URL,
    withCredentials: true,
}); 




const notification = (title) => {
  toast.error(title);
}

export default axiosInstance;