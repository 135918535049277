import { SimpleModal } from 'component/Models/Modal'
import React, {useState, useEffect, useContext} from 'react'
import toast from 'react-hot-toast'
import { useMoralis, useNativeBalance } from 'react-moralis'
import { submitQuestApi, } from 'services/wallet'
import {logEvent} from 'utilis/logEvents'


const ConnectWallet = ({show, setShow, discordHash, questId, quest, guildId}) => {
    
    const {authenticate} = useMoralis();
    const [loading, setLoading] = useState(false);
   

    const isMetaMaskInstalled = () => {
        const { ethereum } = window;
        return Boolean(ethereum && ethereum.isMetaMask);
    };
  
    const isPhantomInstalled = () => {
        const { phantom } = window;
        return Boolean(phantom);
    };




    const connectWallet = async (type) => {
        try{
            let address;
            if(type === 'eth'){
                address = await connectMetamaskWallet()
            }
            if(type === 'sol'){
                address = await connectSolanaWallet()
            }
            console.log("Done");

            logEvent('QUEST::WEBSITE::COMPLETE', {
                questId,
                guildId,
                address: address,
                discordHash: discordHash,
            })
            setLoading(false);
            toast.success("Your wallet has been succesfully added!")
            setTimeout(() => {
                window.location.href = (`https://discord.com/channels/${guildId}`);
            }, 1000)

        } catch(err){
            console.log(err)
            console.log('err')
            toast.error("Something went wrong, please try again")
            logEvent('QUEST::WEBSITE::ERROR', {
                questId,
                guildId,
                errorType: 'error'
            })
            setLoading(false);
            setShow(false)
        }
    };



    const connectSolanaWallet = async () => {
        if(!isPhantomInstalled()){
            toast.error('Please install Phantom wallet!')
            logEvent('QUEST::WEBSITE::ERROR', {
                questId,
                guildId,
                errorType: 'WALLET_NOT_INSTALLED'
            })
            return;
        }
        setLoading('phantom');
        try{
            const res = await window.phantom.solana.connect()
            const account_ = res.publicKey.toString()
            if(account_) {
                await submitQuestApi(questId, {
                    discordHash,
                    submission: {
                        address: account_,
                    },
                    type: 'FROM_WEBSITE'
                })
                return account_;
            }
        } catch(err){
            throw err;
        }
    }
    

    const connectMetamaskWallet = async () => {
        if(!isMetaMaskInstalled()){
            toast.error('Please install MetaMask wallet!')
            logEvent('QUEST::WEBSITE::ERROR', {
                questId,
                guildId,
                errorType: 'WALLET_NOT_INSTALLED'
            })
            return;
        }
        setLoading('metamask');
        try{
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log(accounts)
            if(accounts && accounts.length > 0) {
                await submitQuestApi(questId, {
                    discordHash,
                    submission: {
                        address: accounts[0],
                    },
                    type: 'FROM_WEBSITE'
                })
                return accounts[0];
            } else {
                throw new Error("")
            }

        } catch(err){
            console.log('err')
            toast.error("Something went wrong, please try again")
        }
    };


    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
        >
            <div class='py-6'>
                <div class='px-6 text-center mb-6'>
                    <div class='h2 mb-1'>Connect your wallet</div>
                    <p>Get access to our airdrops and other exciting rewards</p>
                </div>

                <div class='row justify-content-center'>
                    {quest?.evaluation?.metadata?.walletChain !== 'solana' && <div class='col-10 mb-4'>
                        <div class='card bg-light card-hover-shadow pointer' onClick={() => connectWallet('eth')}>
                            <div class='card-body d-flex align-items-center justify-content-center'>
                                <img src = "/assets/svg/brands/metamask.png" class='img-fluid' style = {{height:'50px'}}/>
                                <div class='bold lead ml-3'>
                                    {loading === 'metamask' ? <span class='spinner-border spinner-border-sm mr-2'></span> : ''}
                                    Connect Metamask
                                </div>
                            </div>
                        </div>
                    </div>}
                    {quest?.evaluation?.metadata?.walletChain === 'solana' && <div class='col-10 mb-4'>
                        <div class='card bg-light card-hover-shadow pointer' onClick={() => connectWallet('sol')}>
                            <div class='card-body d-flex align-items-center justify-content-center'>
                                <img src = "/assets/brands/phantom.png" class='img-fluid' style = {{height:'50px'}}/>
                                <div class='bold lead ml-3'>
                                    {loading === 'phantom' ? <span class='spinner-border spinner-border-sm mr-2'></span> : ''}
                                    Connect Phantom
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            
        </SimpleModal>
    )
}

export default ConnectWallet