import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import {logPageView} from 'utilis/logEvents'
import Wallet from 'pages/Wallet'
import Airdrops from 'pages/Airdrop';
import Quests from 'pages/Quests';
import Notification from 'pages/Notifications';
import DiscordAuth from 'pages/DiscordAuth';
import ScrollToTop from 'component/HOC/ScrollToTop';
import Navbar from 'component/Nav';



const AppRouter = (props) => {

    const history = useHistory()

    useEffect(() => {
        logPageView()
        history.listen((location) => {
            logPageView()
        })
    }, [])

    return (
        <>
        <ScrollToTop>
            {/* <Navbar /> */}
            <Switch>
                <Route path="/wallet-connect" exact component={Wallet} />
                <Route path="/set-up-notification" exact component={Notification} />
                <Redirect from = "*" to = "/" />
            </Switch>
        </ScrollToTop>
        </>
    );
}

export default AppRouter;


